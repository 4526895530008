import React from "react";

import { QueryClient } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";

import { PageProps } from "gatsby";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Navigation from "../components/Navigation";

const CACHE_TIME = 1000 * 60 * 60;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      gcTime: CACHE_TIME,
      retry: 5,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: typeof window !== "undefined" ? window.localStorage : null,
});

function ApplicationLayout({
  children,
  path,
}: {
  children: React.ReactNode;
  path: string;
}) {
  return (
    <div>
      <Navigation />
      {children}
    </div>
  );
}

const Dapp = <T extends PageProps>(props: {
  children: React.ReactElement<T>;
  path: string;
}) => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        maxAge: CACHE_TIME,
        dehydrateOptions: {
          shouldDehydrateQuery: (query) => {
            return [""].includes(query.queryKey[0] as string);
          },
        },
      }}
    >
      <ReactQueryDevtools initialIsOpen={false} />
      <ApplicationLayout path={props.path}>{props.children}</ApplicationLayout>
    </PersistQueryClientProvider>
  );
};

export default function app<T extends PageProps>(
  WrappedComponent: React.ReactElement<T>,
  props: T
) {
  return <Dapp {...props}>{WrappedComponent}</Dapp>;
}
