import "./src/styles/global.css";

import app from "./src/hoc/app";

export const wrapPageElement = ({
  element,
  props,
}: {
  element: React.ReactElement;
  props: Record<string, unknown> & { location: Location };
}) => {
  return app(element, props);
};
